<!--
 * @Author: lzh
 * @Date: 2022-07-06 11:13:43
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-12 16:20:16
 * @Description: file content
-->
<template>
  <div
    class="company-box px-company-box"
    @click="clickItem('box')"
    :class="`edit_${Xindex}_box`"
  >
    <div class="top-box">
      <h1
        class="title"
        v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
      ></h1>
      <h2
        class="desc"
        v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
      ></h2>
    </div>
    <div
      class="center-box"
      v-html="$util.rex.getHtml(configs[`${nowL}centerText`] || configs.centerText)"
    ></div>
    <div class="button-box">
      <div class="item-box">
        <div
          class="item"
          v-for="(item, index) in configs.items"
          :key="index"
          :style="index == 0 ? 'margin-left:0;' : ''"
        >
          <img
            class="img"
            :src="item.img"
          />
          <div
            class="title"
            v-html="$util.rex.getHtml(item[`${nowL}title`] || item.title)"
          ></div>
          <div
            class="contact"
            v-html="$util.rex.getHtml(item[`${nowL}contact`] || item.contact)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang'
export default {
  name: 'self-company',
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {
          title: '薑軟件有限公司',
          desc: 'Gingersoft Limited',
          centerText: '',
          items: [
            {
              img: require('../../assets/material/聯絡我們/dianhua-icon.png'),
              title: '聯繫電話',
              contact: '852 8200 2022',
            },
            {
              img: require('../../assets/material/聯絡我們/youxiang-icon.png'),
              title: '郵箱地址',
              contact: 'info@gingersoft.com.hk',
            },
            {
              img: require('../../assets/material/聯絡我們/facebook-icon.png'),
              title: '社交平台',
              contact: 'Facebook',
            },
          ],
        };
      },
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      _isMobile: '',
    };
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
    // this._isMobile = this.$util.window._isMobile();
  },
  methods: {
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.company-box {
  padding: 50px 320px;
  padding-top: 70px;
  font-family: PingFang SC-Medium, PingFang SC;
  .top-box {
    padding: 24px 0 14px 0;
    text-align: center;
    .title {
      font-size: 36px;
      font-weight: 500;
      color: #000000;
    }
    .desc {
      font-size: 32px;
      font-weight: 400;
      color: #999999;
    }
  }
  .center-box {
    font-size: 24px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #101010;
  }
  .button-box {
    // padding: 30px 20px;
    // background-color: #f6f6f6;
    margin-top: 40px;
    .item-box {
      display: flex;
      align-items: center;

      .item {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        flex: 1;
        box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.06),
          -4px -4px 15px 0px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        padding: 24px 0;
        .img {
          width: 48px;
        }
        .title {
          font-size: 14px;
          font-weight: 400;
          color: #000000;
          padding-top: 20px;
        }
        .contact {
          font-size: 20px;
          font-weight: 400;
          color: #000000;
        }
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .px-company-box {
    padding: 25px 0;
    .top-box {
      .title {
        font-size: 26px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
      }
      .desc {
        font-size: 26px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
    .center-box {
      padding: 5px 17px;
      font-size: 14px;
    }
    .button-box {
      padding: 6px 15px;
      // background: #fff;
      .item-box {
        padding: 15px;
        // background: #f6f6f6;
        .item {
          margin-left: 10px;
          .img {
            width: 36px;
            height: 36px;
            margin-top: 8px;
          }
          .title {
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            padding-top: 25px;
          }
          .contact {
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
          }
        }
      }
    }
  }
}
@media screen and(max-width:650px) {
  .px-company-box {
    .center-box {
      padding: 10px 34px 0;
    }
    .button-box {
      .item-box {
        .item {
          .contact {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .px-company-box {
    padding: 25px 0 0;
    .top-box {
      .title {
        font-size: 13px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
      }
      .desc {
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
    .center-box {
      font-size: 10px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #101010;
      line-height: 14px;
      padding: 5px 17px;
    }
    .button-box {
      padding: 0 9px;
      margin: 15px 0 0;
      // background: #f6f6f6;
      .item-box {
        padding: 0;
        .item {
          margin-left: 6px;
          padding: 8px 4px;
          box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.06),
            -1px -1px 3px 0px rgba(0, 0, 0, 0.06);
          border-radius: 4px;
          .img {
            width: 14px;
            height: auto;
            margin-top: 0;
            // margin-top: 8px;
          }
          .title {
            font-size: 10px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            padding-top: 10px;
          }
          .contact {
            font-size: 10px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
          }
        }
      }
    }
  }
}
</style>
